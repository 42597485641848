import { Pagination } from 'antd'
import React, { useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

function getGraphWidth() {
    if (typeof window !== 'undefined') {
        const { innerWidth: width } = window
        if (width > 600) {
            return 450
        }
        return width - 50
    }
    return 0
}

export function PDFViewer({
    filePath,
    height,
    widthDescreasePx = 0,
}: {
    filePath: string
    height?: number
    widthDescreasePx?: number
}) {
    const [numPages, setNumPages] = useState(10)
    const [pageNumber, setPageNumber] = useState(1)
    const [graphWidth, setGraphWidth] = useState<number>(getGraphWidth())

    useEffect(() => {
        if (typeof window !== 'undefined') {
            function handleResize() {
                setGraphWidth(getGraphWidth())
            }
            window.addEventListener('resize', handleResize)
            return () => window.removeEventListener('resize', handleResize)
        }
    }, [])
    useEffect(() => {
        if (typeof window !== 'undefined') {
            function handleResize() {
                setGraphWidth(getGraphWidth())
            }
            window.addEventListener('orientationchange', handleResize)
            return () => window.removeEventListener('orientationchange', handleResize)
        }
    }, [])

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages)
    }

    function changePage(p) {
        setPageNumber(p)
    }

    return (
        <div>
            <Document file={filePath} onLoadSuccess={onDocumentLoadSuccess}>
                <Page
                    pageNumber={pageNumber}
                    {...(!!height ? { height: height } : { width: graphWidth - widthDescreasePx })}
                />
            </Document>
            <Pagination simple current={pageNumber} total={numPages * 10} onChange={changePage} />
        </div>
    )
}
